// @ts-nocheck
import React, { useEffect, useState, useRef, FC } from "react";
import Img from "gatsby-image";
import styled from "styled-components";

/**
 * @param {object} props Component props
 * @param {object} props.callbacks interface for callback functions (pass this to Pagination, if used)
 * @param {(newIndex:number) => void} props.callbacks.atIndex sets background to specified index
 * @param {(prevIndex: number, newIndex: number) => void} props.callbacks.onChange user-provided callback fired when background image changes
 * @param {Function} props.callbacks.next sets background to the next one
 * @param {Function} props.callbacks.prev sets background to the previous one
 * @param {string[]} props.images list of specified image file names to set order
 * @param {number} [props.duration=5] duration background is shown for
 * @param {number} [props.transition=2] length of transition
 * @param {object.isRequired} props.query result of GraphQL query for images
 * @param {JSXElement[]} props.children subtitles/captions in sync for respective background images;
 * (<React.Fragment/> to skip over one)
 * @param {Array} props.gatsbyImageProps remaining props spread onto Img elements
 * @param {number} [props.initDelay=5] initial delay before first transition
 */

const Counter = styled.div`
  color: var(--lighter-gray);
  position: fixed;
  bottom: 0;
  left: var(--s12);
  bottom: var(--s12);
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 300;

  @media (max-width: 700px) {
    font-size: 1.2rem;
    line-height: 1.8rem;
    left: var(--s4);
  }
`;

const BackgroundSlider: FC<any> = ({
  callbacks,
  images,
  duration,
  transition,
  initDelay,
  query,
  children,
  ...gatsbyImageProps
}) => {
  let nodes = [];
  let bgRefs = [];
  let subRefs = [];
  let bgWrappers = [];
  let subWrappers = [];
  const { style, ...imageProps } = gatsbyImageProps;

  nodes.safePush = function (data) {
    if (data) {
      return this.push(data);
    } else {
      return this.length;
    }
  };

  if (images) {
    for (let image of images) {
      nodes.safePush(query.backgrounds.nodes.find(node => node.relativePath === image));
    }
  } else {
    nodes = query.backgrounds.nodes;
  }

  const imgs = nodes.map((data, index) => {
    const backgroundStyle = {
      position: "absolute",
      zIndex: -10,
      width: "100%",
      height: "100%",
      margin: 0,
      padding: "none",
      left: 0,
      top: 0,
      backgroundSize: "cover",
      opacity: index ? 0 : 1,
      transition: `opacity ${transition}s`,
    };

    const subStyle = {
      opacity: index ? 0 : 1,
      transition: `opacity ${transition}s`,
      pointerEvents: index ? "none" : "auto",
    };

    subRefs[index] = React.createRef();
    bgRefs[index] = React.createRef();

    return (
      <React.Fragment key={index}>
        <div ref={bgRefs[index]}>
          <Img loading="lazy" fluid={data.childImageSharp.fluid} style={{ ...backgroundStyle, ...style }} {...imageProps} />
        </div>
        <div ref={subRefs[index]} style={subStyle}>
          {React.Children.toArray(children)[index]}
        </div>
      </React.Fragment>
    );
  });

  const [timeoutHandle, setTimeoutHandle] = useState(0);
  const timeoutHandleRef = useRef(timeoutHandle);
  timeoutHandleRef.current = timeoutHandle;
  const [idx, setIndex] = useState(0);
  const indexRef = useRef(idx);
  indexRef.current = idx;

  const clearAndSetTimeoutHandle = newTimeoutHandle => {
    clearTimeout(timeoutHandleRef.current);
    setTimeoutHandle(newTimeoutHandle);
  };

  const initEffect = (flag: boolean) => {
    bgRefs.forEach(bgRef => {
      bgWrappers.push(bgRef.current.firstElementChild);
    });

    subRefs.forEach(subRef => {
      subWrappers.push(subRef.current);
    });

    const length = bgWrappers.length;

    function changeIndex(newIndex) {
      const index = indexRef.current;
      clearTimeout(timeoutHandleRef.current);

      bgWrappers[index].style.opacity = 0;
      bgWrappers[newIndex % length].style.opacity = 1;

      subWrappers[index].style.opacity = 0;
      subWrappers[index].style.pointerEvents = "none";

      subWrappers[newIndex % length].style.opacity = 1;
      subWrappers[newIndex % length].style.pointerEvents = "auto";

      setIndex(newIndex % length);
      clearAndSetTimeoutHandle(setTimeout(callback, duration * 1000));
    }

    const callback = function () {
      const index = indexRef.current;
      changeIndex(index + 1);
    };

    if (flag) {
      callback();
    } else {
      clearAndSetTimeoutHandle(setTimeout(callback, initDelay * 1000));
    }

    return () => {
      clearTimeout(timeoutHandleRef.current);
    };
  };

  useEffect(() => initEffect(false), []);

  return (
    <div onClick={() => initEffect(true)}>
      {imgs}
      <Counter>
        {idx + 1}/{bgRefs && bgRefs.length && bgRefs.length}
      </Counter>
    </div>
  );
};

BackgroundSlider.defaultProps = {
  images: null,
  duration: 8,
  transition: 1.5,
  initDelay: 8,
};

export default BackgroundSlider;
