import React, { FC, useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import BackgroundSlider from "../components/BackgroundSlider";
import { Routes } from "../constants/routes";


const SLIDESHOW_QUERY = graphql`
  query {
    backgrounds: allFile(sort: { fields: name }, filter: { sourceInstanceName: { eq: "slideshow" } }) {
      nodes {
        relativePath
        childImageSharp {
          fluid(maxWidth: 1000, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

const IndexPage: FC = () => {
  return (
    <Layout activePage={Routes.HOME}>
      <SEO title="Home" />
      <BackgroundSlider query={useStaticQuery(SLIDESHOW_QUERY)} />
    </Layout>
  );
};

export default IndexPage;
